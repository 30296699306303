import React, { useState, useEffect } from 'react';

const Countdown = ({ targetDate, onEnd, onTimeLeftChange }) => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));

    useEffect(() => {

        if (Object.keys(timeLeft).length > 0 && Object.values(timeLeft).every(value => value <= 0)) {
            //onEnd(); // Call the onEnd callback when the countdown ends
            //return;
        }

        onTimeLeftChange(timeLeft);

        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft(targetDate));
        }, 1000);

        return () => clearTimeout(timer);
    }, [timeLeft, targetDate]);

    function calculateTimeLeft(targetDate) {
        const difference = +new Date(targetDate) - +new Date();

        let timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    }

    return (
        <h2 style={{ color: "#fff", fontFamily: "iceland", fontSize: "20px", fontWeight: "bolder" }}>
            {timeLeft.days || '0'} day{timeLeft.days > 1 ? 's' : ''}, {timeLeft.hours || '0'} hour{timeLeft.hours > 1 ? 's' : ''},
            {timeLeft.minutes || '0'} minute{timeLeft.minutes > 1 ? 's' : ''}, {timeLeft.seconds || '0'} second{timeLeft.seconds > 1 ? 's' : ''}
        </h2>
    );
};

export default Countdown;
